import { Session } from "next-auth";
import { tokenDuration } from "./constants";

export interface HardcoverSession extends Session {
  id?: number;
  token: string;
}

let session: HardcoverSession;
let updatedAt: number;
let fetchSessionPromise: Promise<HardcoverSession>;

export const getToken = (): string => {
  return session.token;
};

export const getSession = (): HardcoverSession | null => {
  return session;
};

export const setSession = (newSession: HardcoverSession | null) => {
  session = newSession;
  updatedAt = Math.floor(Date.now() / 1000);
};

export const expireSession = () => {
  updatedAt = null;
  session = null;
  fetchSessionPromise = null;
};

const generateSessionPromise = () => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise<HardcoverSession>(async (resolve) => {
    if (!session) {
      const host =
        typeof window !== "undefined"
          ? window.location.origin
          : process.env.NEXTAUTH_URL;
      session = (await fetch(`${host}/api/defaultUserSession`).then((r) =>
        r.json()
      )) as HardcoverSession;
    }

    resolve(session);
  });
};

export const fetchSession = (): Promise<HardcoverSession> => {
  const now = Math.floor(Date.now() / 1000);
  if (!updatedAt || updatedAt < now - tokenDuration) {
    updatedAt = Math.floor(Date.now() / 1000);
    session = null;
    fetchSessionPromise = generateSessionPromise();
  } else {
    fetchSessionPromise = fetchSessionPromise || generateSessionPromise();
  }
  return fetchSessionPromise;
};
