/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

export type UiStateType = {
  loginVisible: null | "Join" | "Login";
  theme: null | "light" | "dark";
};

export const uiState: UiStateType = {
  loginVisible: null,
  theme: null,
};

const uiSlice = createSlice({
  name: "UiSlice",
  initialState: uiState,
  reducers: {
    toggleLoginVisible(
      state,
      { payload }: { payload: null | "Join" | "Login" }
    ) {
      state.loginVisible = payload;
    },
    setTheme(state, { payload }: { payload: "light" | "dark" }) {
      state.theme = payload;
    },
  },
});

export const uiActions = uiSlice.actions;

export default uiSlice.reducer;
