/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { HardcoverSession, setSession } from "lib/authorization/tokenLoader";
import { UserType } from "types";

export type GdprAnswer = {
  all: boolean;
};

export type CurrentUserStateType = {
  gdpr: GdprAnswer | null;
  session: HardcoverSession;
  userLoaded: boolean;
  reloadUser: boolean;
  user: UserType | null;
  unfoundBookMatchScores: number[];
};

export const currentUserState: CurrentUserStateType = {
  gdpr: null,
  session: null,
  userLoaded: false,
  reloadUser: false,
  user: null,
  unfoundBookMatchScores: [],
};

const currentUserSlice = createSlice({
  name: "CurrentUserSlice",
  initialState: currentUserState,
  reducers: {
    setSession(state, { payload }) {
      if (state.session !== payload) {
        setSession(payload);
        state.session = payload;
      }
    },
    setGdpr(state, { payload }: { payload: GdprAnswer | null }) {
      state.gdpr = payload;
    },
    setUserLoaded(state, { payload }) {
      state.userLoaded = !!payload;
      if (!payload) {
        state.user = null;
      }
    },
    setReloadUser(state, { payload }) {
      state.reloadUser = payload;
    },
    setInitialUser(state, { payload }: { payload: UserType }) {
      state.user = payload;
      state.userLoaded = true;
      state.reloadUser = false;
    },
    setUser(state, { payload }: { payload: UserType }) {
      state.user = payload;
      state.userLoaded = !!payload;
      state.reloadUser = false;
    },
    appendUnfoundBookMatchScores(state, { payload }: { payload: number[] }) {
      state.unfoundBookMatchScores = [
        ...state.unfoundBookMatchScores,
        ...payload,
      ];
    },
    setNotificationsCount(state, { payload }: { payload: number }) {
      if (state.user) {
        state.user.notificationsCount = payload;
      }
    },
  },
});

export const currentUserActions = currentUserSlice.actions;

export default currentUserSlice.reducer;
