"use client";

/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

const quietMode = Boolean(process.env.NEXT_PUBLIC_BUGSNAG_QUIET ?? true);
const quietLogger = {
  debug() {},
  info() {},
  warn() {},
  error() {},
};

Bugsnag.start({
  apiKey: process.env.NEXT_PUBLIC_BUGSNAG_KEY,
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ["production"],
  releaseStage: process.env.NODE_ENV,
  generateAnonymousId: false,
  ...(quietMode ? { logger: quietLogger } : {}),
});

export default Bugsnag;

export const ErrorBoundary =
  Bugsnag.getPlugin("react")?.createErrorBoundary(React);
