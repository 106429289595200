"use client";

import { expireSession, fetchSession } from "lib/authorization/tokenLoader";

import { useDispatch } from "react-redux";
import { currentUserActions } from "store/currentUser/currentUserSlice";

export function useCurrentSession() {
  const dispatch = useDispatch();

  async function resetSession(setUnloaded = true) {
    if (setUnloaded) {
      dispatch(currentUserActions.setUserLoaded(false));
    }
    expireSession();
    const newSession = await fetchSession();
    dispatch(currentUserActions.setSession(newSession));
    return newSession;
  }

  return {
    resetSession,
  };
}
