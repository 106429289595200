import { SearchType } from "components/search/search/types";

export const indexName = {
  Authors: `Author_${process.env.NODE_ENV}`,
  Books: `Book_${process.env.NODE_ENV}`,
  Characters: `Character_${process.env.NODE_ENV}`,
  Series: `Series_${process.env.NODE_ENV}`,
  Lists: `List_${process.env.NODE_ENV}`,
  Prompts: `Prompt_${process.env.NODE_ENV}`,
  Publishers: `Publisher_${process.env.NODE_ENV}`,
  Users: `User_${process.env.NODE_ENV}`,
};

export function indexLookup(type: SearchType) {
  return indexName[type];
}
