"use client";

import { useState, useEffect } from "react";
import { tailwindThemeScreens } from "constants/tailwind";
import { useWindowWidth } from "@react-hook/window-size";

export const useIsScreenSizeBiggerThanTailwindSize = (
  size: string
): boolean => {
  const [isBigger, setIsBigger] = useState(null);
  const width = useWindowWidth({ initialWidth: null });

  useEffect(() => {
    if (!width) {
      // Do nothing
    } else {
      setIsBigger(width >= tailwindThemeScreens[size]);
    }
  }, [width]);

  return isBigger;
};
