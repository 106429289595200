"use client";

import Button from "hardcover-ui/components/Button";
import { ButtonThemeProps } from "hardcover-ui/theme/components/button";
import { useRouter } from "next/navigation";
import { ReactElement } from "react";

type BackLinkProps = {
  size?: ButtonThemeProps["size"];
  variant?: ButtonThemeProps["variant"];
  children: ReactElement;
};

export function BackButton({
  size = "lg",
  variant = "primary",
  children,
}: BackLinkProps) {
  const router = useRouter();

  function goBack() {
    router.back();
    router.refresh();
  }

  return (
    <Button variant={variant} size={size} onClick={() => goBack()}>
      {children}
    </Button>
  );
}
