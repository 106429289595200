"use client";

import LoadingSpinner from "hardcover-ui/components/LoadingSpinner";
import Button from "hardcover-ui/components/Button";
import { Suspense, lazy, useEffect } from "react";
import Text from "hardcover-ui/components/Text";
import NotFoundView from "./NotFoundView";
import Bugsnag from "lib/bugsnag/client";

const ErrorView = lazy(() => import("./ErrorView"));

export default function ServerErrorView({
  clearError,
  error,
}: {
  error: Error;
  clearError: () => void;
}) {
  useEffect(() => {
    if (error?.message !== "NEXT_NOT_FOUND") {
      Bugsnag.notify(error);
    }
  }, []);

  if (error?.message === "NEXT_NOT_FOUND") {
    return <NotFoundView />;
  }

  return (
    <Suspense fallback={<LoadingSpinner variant="layout" />}>
      <ErrorView>
        <Text
          variant="secondary"
          as="p"
          className="max-w-lg text-center mx-auto"
        >
          We're very embarrassed by this, but something went wrong. Adam &amp;
          Ste have been notified and they're on the case!
        </Text>
        <p className="mt-4">
          <Button variant="primary" size="lg" onClick={() => clearError()}>
            Try Again?
          </Button>
        </p>
      </ErrorView>
    </Suspense>
  );
}
