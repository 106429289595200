import { BackButton } from "components/ui/BackButton";
import Container from "hardcover-ui/components/Container";
import Heading from "hardcover-ui/components/Heading";
import Text from "hardcover-ui/components/Text";
import { enlargeUrl } from "lib/imageTools";
import { ReactNode } from "react";

type NotFoundViewProps = {
  children?: ReactNode;
};

export default function NotFoundView({ children }: NotFoundViewProps) {
  return (
    <Container variant="layout" size="sm" className="text-center">
      <img
        src={enlargeUrl({
          path: "/static/errors/404-not-found.png",
          width: 480,
          height: 396,
        })}
        alt="Jules looking at a map with the text 'dead end'"
        width={480}
        height={396}
        className="mx-auto"
      />

      <Heading level={1}>What you're searching for isn't here.</Heading>

      <div>
        {children ? (
          <>{children}</>
        ) : (
          <Text variant="secondary" as="p" className="mb-4">
            We could not find this place on Hardcover.
          </Text>
        )}

        <p className="mt-4">
          <BackButton size="lg">
            <>Go back</>
          </BackButton>
        </p>
      </div>
    </Container>
  );
}
