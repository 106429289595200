/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { SearchContextType, SearchType } from "components/search/search/types";
import { indexLookup } from "lib/search/indexLookup";

export type SearchStateType = {
  searchVisible: boolean;
  searchTerm: null | string;
  nextSearchTerm: null | string;
  searchSelected: null | any;
  searchTypes: SearchType[];
  initialSearchType: SearchType;
  activeSearchType: SearchType;
  indexName: string;
  searchMultipleSources: boolean;
  nextActiveSearchType: SearchType;
};

export const searchState: SearchStateType = {
  searchVisible: false,
  searchTerm: null,
  searchSelected: null,
  searchMultipleSources: false,
  searchTypes: ["Books"],
  initialSearchType: "Books",
  activeSearchType: "Books",
  nextActiveSearchType: "Books",
  nextSearchTerm: null,
  indexName: indexLookup("Books"),
};

const searchSlice = createSlice({
  name: "SearchSlice",
  initialState: searchState,
  reducers: {
    toggleSearchVisible(state) {
      state.searchVisible = !state.searchVisible;
      state.searchTerm = state.searchVisible ? state.searchTerm : null;
    },
    reset(state) {
      state.searchVisible = false;
      state.searchTerm = null;
      state.searchSelected = null;
      state.searchMultipleSources = false;
      state.searchTypes = ["Books"];
      state.initialSearchType = "Books";
      state.activeSearchType = "Books";
      state.nextActiveSearchType = null;
      state.nextSearchTerm = null;
      state.indexName = indexLookup("Books");
    },
    setSearchVisible(state, { payload }: { payload: boolean }) {
      state.searchVisible = payload;
      state.searchTerm = payload ? state.searchTerm : null;
      state.nextSearchTerm = null;
      state.searchTypes = ["Books"];
      state.initialSearchType = "Books";
      state.indexName = indexLookup("Books");
      state.nextActiveSearchType = null;
    },
    setNextSearchTerm(state, { payload }: { payload: string | null }) {
      state.nextSearchTerm = payload;
    },
    setSearchTerm(state, { payload }: { payload: string | null }) {
      state.searchTerm = payload;
    },
    setSearchSelected(state, { payload }: { payload: any }) {
      state.searchSelected = payload;
    },
    setActiveSearchType(state, { payload }: { payload: SearchType }) {
      state.activeSearchType = payload;
      state.indexName = indexLookup(payload);
      state.nextActiveSearchType = null;
    },
    willSetActiveSearchType(state, { payload }: { payload: SearchType }) {
      state.nextActiveSearchType = payload;
    },
    setSearch(state, { payload }: { payload: SearchContextType }) {
      state.searchTypes = payload.searchTypes;
      state.initialSearchType = payload.initialSearchType;
      state.indexName = indexLookup(payload.initialSearchType);
      state.searchMultipleSources = payload.searchMultipleSources || false;
    },
  },
});

export const searchActions = searchSlice.actions;

export default searchSlice.reducer;
